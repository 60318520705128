module.exports = {
  title: `dobby's small workplace`,
  description: `I'M FREE ...`,
  author: `dobby`,
  introduction: `The beautiful thing in the world. the most precious thing in the universe.`,
  siteUrl: `https://dobby.work`, // Your blog site url
  social: {
    twitter: ``,
    github: ``,
    medium: ``,
    facebook: ``,
  },
  icon: `content/assets/dobby.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: 'dobby-1',
    utterances: '',
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '422344231795051', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-151903832-1', // Add your google analytics tranking ID
}
